import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import AnexoIcone from "../../../../../components/AnexoIcone"

export default function Mensagens({ mensagens }) {
  function openImage(url) {
    window.open(url, "_blank");
  }
  function renderMensagem(mensagem) {
    const date = moment(mensagem.criado_em).format("DD/MM/YYYY, HH:mm");
    const { tipo, nome, text, url, tipo_upload, arquivos } = mensagem;
    const className = tipo === 'A' ? "Messages-message currentMember" : "Messages-message";
    function itemAnexo(arquivo){
      return(
        <div className="anexo-section" onClick={()=>{window.open(arquivo.url, '_blank')}}>
          <AnexoIcone anexo={arquivo?.nome?.split('.').pop()} style={{ fontSize: "1rem" }} />
          <span>{arquivo?.nome}</span>
        </div>
      )
    }

    return (
        <li className={className}>
          <div className="Message-content">
            <div className="username">{date + " " + nome}</div>
            <div className="text">{url ?
                tipo_upload == 'I' ?
                    <img onClick={() => openImage(url)} style={{maxWidth: "100%", cursor: "pointer"}} src={url} alt={<div dangerouslySetInnerHTML={{ __html: text}}></div>} /> :
                    <div onClick={() => openImage(url)} style={{ cursor: "pointer"}}>
                      <FontAwesomeIcon icon={faPaperclip} color="white" />  {<div dangerouslySetInnerHTML={{ __html: text}}></div>}
                    </div>
                : <div dangerouslySetInnerHTML={{ __html: text}}></div>}
                {arquivos && arquivos.length && 
                <>
                  <hr/>
                  {arquivos.map((arquivo, index)=>{
                    return (
                      <div key={index}>
                        {itemAnexo(arquivo)}
                      </div>
                    )
                  })}
                </>
              }
              </div>
          </div>
        </li>
    );
  }

  return (
      <ul className="Messages-list">{mensagens.map((m) => renderMensagem(m))}</ul>
  );
}
