/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../LoginCliente/index.css";
import fundo from "../../../assets/Fundo_dommus.png";
import { Spinner } from "react-bootstrap";
import { mascaraCpfCnpj } from "../../../components/InputTextMask";
import  appStore from "../../../assets/mobile/apple.svg";
import  playStore from "../../../assets/mobile/google.png";
import "./index.css"
import styled from "styled-components";

export default function Login({
  logoCliente,
  corPrimaria,
  corSecundaria,
  corFundo,
  imagemFundo,
  slug,
  setCpf,
  cpf,
  loadingDados,
  handleSubmit,
  appsBadges,
  imagemFundoMobile
}) {
  const Container = styled.div`
    background-color: ${corFundo};
    ${(imagemFundoMobile && `@media (max-width: 767px) {
      background-image: url('${imagemFundoMobile}')!important;
      background-size: cover!important;
      background-position: center!important;
      background-repeat: no-repeat!important;
    }`) || ''}
  `;

  function splitaCor() {
    const corSplitada = corPrimaria.split("_");
    if (corSplitada.length >= 2) {
      return {
        background: `linear-gradient(to right, ${corSplitada[0]} , ${corSplitada[1]})`,
      };
    } else {
      return { backgroundColor: `${corSplitada[0]}` };
    }
  }

  return (
    <div className="login">
      <div className="imagemLogin">
        <div
          className="imagemFundoLogin"
          style={
            imagemFundo
              ? {
                  background: `url(${imagemFundo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }
              : {
                  background: `url(${fundo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }
          }
        >
          <div className="logo-dommus">
            <img src={logoCliente} alt={slug} />
          </div>
        </div>
      </div>
      <Container className="container">
        <div className="logo-dommus mobile">
          <img src={logoCliente} alt={slug} />
        </div>
        <div className="content">
          <p>
            <strong>Informe seu CPF ou CNPJ: </strong>
          </p>

          <form onSubmit={handleSubmit}>
            <label htmlFor="cpf">CPF/CNPJ</label>
            <input
              id="cpf"
              placeholder="Digite seu CPF ou CNPJ"
              value={mascaraCpfCnpj(cpf)}
              onChange={(event) => setCpf(event.target.value)}
            />

            <div className="buttons">
              <button
                className="btn btn-submit"
                type="submit"
                style={splitaCor()}
              >
                {loadingDados ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Acessar"
                )}
              </button>
            </div>
          </form>
        <div className="container-badges-icons">
           {appsBadges.android && (
            <a href={appsBadges.android}>
              <img className="play-store" src={playStore}/>
            </a>
           )}
           {appsBadges.ios && (
            <a href={appsBadges.ios}>
              <img className="app-store" src={appStore}/>
            </a>
           )}
        </div>
        </div>
      </Container>

        
    </div>
  );
}
